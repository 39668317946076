import dayjs from "dayjs";
import { IData } from "../components/Form";
import { IPlan } from "../components/Plan";
import { getPaymentResult, hash, IPaymentData, mainApi } from "./api";
import { getUTMCommentForCP } from "./cloudPayments";
import { googleEvent, yandexEvent } from "./metrics";
import { getIokaLink, PaymentScheme } from "./payments";
import { getWidgetInstance } from './utils';


export default async function submit(
  formData: IData,
  selectedPlan: IPlan,
  setPayedStatus: (status: "success" | "failed") => void,
  setHref: (operation: string) => void,
  token: string,
  partner: string,
  promoError: (m: string) => void,
  setShowLoader: (v: boolean) => void
) {
  let funcResult: null | string = null;

  const { name, email, time, promo, date, autoprediction, country, gender } = formData;

  const { name: place, lat, lon } = formData.place!;
  try {
    let relations = selectedPlan.id === 'relations';
    let finance = selectedPlan.id === 'finance' || selectedPlan.id === 'relations';
    let birth = false;

    if (selectedPlan.type === 'lablife') {
      if (selectedPlan.id === 'default') {
        relations = true;
        finance = true;
      }
      if (selectedPlan.id === 'birth') {
        birth = true;
      }
    }

    const dataPost: IPaymentData = {
      email,
      gender,
      relations,
      finance,
      partner,
      birth,
      promo,
      name,
      date: date!,
      time: time!,
      place,
      lat,
      lon,
      token,
      autoprediction,
      apDate: dayjs().format('DD.MM.YYYY'),
      apTime: dayjs().format('HH:mm:ss'),
      country,
      backUrl: getIokaLink('back'),
      failUrl: getIokaLink('fail'),
      successUrl: getIokaLink('success'),
      // captchaToken
    };

    if (partner) dataPost.partner = partner;
    dataPost.operation = await hash(JSON.stringify(dataPost));
    dataPost.comment = getUTMCommentForCP();
    setHref(dataPost.operation)
    setShowLoader(true)
    const result: any = await getPaymentResult(dataPost, token, partner)
    setShowLoader(false)

    const onSuccess = () => {
      if (getWidgetInstance() === 'ipr2023') yandexEvent('integr-widget_success-payment', 'reachGoal', '90908943');
      yandexEvent('widget-payment-success');
      setPayedStatus('success');
    };

    if (result?.ioka?.success && result?.ioka?.url) {
      window.location.href = result.ioka.url;
      return;
    }
    if (result.promo && result.success) {
      googleEvent('widget_promo_enter');
      onSuccess();
      return;
    }

    if (result.failed && result.data.length > 0) {
      const error = result.data[0].trim();
      switch (error) {
        case 'PROMO_ALREADY_USED':
          promoError('Промокод уже был использован')
          return;
        case 'PROMO_EXPIRED':
          promoError('Срок действия промокода истек')
          return;
        case 'PROMO_NOT_FOUND':
          promoError('Промокод не найден')
          return;
      }
      throw 'Config error';
    }

    const CustomerReceipt = { ...result.receipt, email };

    const data: IPaymentData = {
      type: 'widget',
      relations,
      finance,
      birth,
      partner: partner,
      cloudPayments: { CustomerReceipt },
      place,
      lat,
      lon,
      name,
      email,
      gender,
      date: date!,
      time: time!,
      promo,
      subtype: 'idp',
      createdAt: Date.now(),
      autoprediction,
      apDate: dayjs().format('DD.MM.YYYY'),
      apTime: dayjs().format('HH:mm:ss'),
      country,
      K8S_NAMESPACE: result.K8S_NAMESPACE,
      // captchaToken
    };

    data.operation = await hash(JSON.stringify(data));
    data.comment = getUTMCommentForCP();
    setHref(data.operation)
    Object.assign(result.options, {
      InvoiceId: token,
      data,
      email,
      requireEmail: true,
      skin: 'mini',
      type: 'widget',
      country,
    });

    const onCancel = () => {
      yandexEvent('widget-payment-cancel');
      setPayedStatus('failed');
    };

    await mainApi.payment(result.options, PaymentScheme.CHARGE, {onSuccess, onCancel})
    yandexEvent('click-postroit-ipr');
  } catch (error) {
    alert('Сервис временно недоступен!');
    console.log(error);
  }

}

export async function submitPartial(
  formData: IData,
  selectedPlan: IPlan,
  setPayedStatus: (status: "success" | "failed") => void,
  setHref: (operation: string) => void,
  token: string,
  partner: string,
  setPromoError: (v: string) => void,
  setShowLoader: (v: boolean) => void
) {
  const { name, email, time, date, gender } = formData;

  const { name: place, lat, lon } = formData.place!;
  try {

    const dataPost: any = {
      email,
      name,
      date,
      time: time,
      place, lat, lon,
      token,
      partner,
      gender,
      rnd: Date.now()
    }

    dataPost.operation = await hash(JSON.stringify(dataPost))
    dataPost.comment = getUTMCommentForCP();
    setHref(dataPost.operation)
    setShowLoader(true)
    const result: any = await getPaymentResult(dataPost, token, partner)
    setShowLoader(false)
    const success = () => {
      if (getWidgetInstance() === 'ipr2023') yandexEvent('integr-widget_success-payment', 'reachGoal', '90908943');
      yandexEvent('widget-payment-success');
      setPayedStatus('success');
    };

    if (result.failed && result.data.length > 0) {
      const error = result.data[0].trim();
      switch (error) {
        case 'INVALID_EMAIL':
          alert('Указан неверный email');
          return;
      }
    }

    if (result.success) {
      success();
    } else if (result.failed) {
      if (result.data[0] === 'INVALID_EMAIL') {
        alert('Указан неверный email');
      }
    } else {
      alert(result.error);
      console.log('failed', result);
    }

  } catch (error) {
    alert('Сервис временно недоступен!');
    console.log(error);
  }

}
