import { searchParams } from "./utils";

export  async function cloudPayments() {
  // todo вынести в апи
  await new Promise((resolve, reject) => {
    const script = document.createElement('script');
    document.head.appendChild(script);
    script.onload = resolve;
    script.onerror = reject;
    script.async = true;
    script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments';
  });
  //@ts-ignore
  return window.cp.CloudPayments;
}

export const getUTMCommentForCP = () => {
  const sp = searchParams()
  const url = window.location.href;

  let isFind: boolean = false;
  const regex = /utm/
  for (let key of sp.keys()) {
    if (regex.test(key)) {
      isFind = true
      break
    }
  }

  return isFind ? url : undefined;
}
