import dayjs from "dayjs";
import { IData } from "../components/Form";
import { IPlan } from "../components/Plan";
import { getStagedPaymentResult, hash, IStagedPaymentData, mainApi } from "./api";
import { getUTMCommentForCP } from "./cloudPayments";
import { googleEvent, yandexEvent } from "./metrics";
import { getIokaLink, PaymentScheme } from "./payments";
import { getWidgetInstance } from './utils';


export default async function submitStaged(
  formData: IData,
  selectedPlan: IPlan,
  setPayedStatus: (status: "success" | "failed") => void,
  setHref: (operation: string) => void,
  token: string,
  partner: string,
  promoError: (m: string) => void
) {
  let funcResult: null | string = null;

  const { email, promo, autoprediction, country, gender,  } = formData; //captchaToken

  try {
    const relations = selectedPlan.id === 'relations';
    const finance = selectedPlan.id === 'finance' || selectedPlan.id === 'relations';
    const lablife = selectedPlan.type === 'lablife' && selectedPlan.id === 'default';
    const birth = selectedPlan.type === 'lablife' && selectedPlan.id === 'birth';

    const dataPost: IStagedPaymentData = {
      email,
      gender,
      relations,
      finance,
      partner,
      lablife,
      birth,
      promo,
      token,
      autoprediction,
      apDate: dayjs().format('DD.MM.YYYY'),
      apTime: dayjs().format('HH:mm:ss'),
      country,
      backUrl: getIokaLink('back'),
      failUrl: getIokaLink('fail'),
      successUrl: getIokaLink('success'),
      // captchaToken
    };


    if (partner) dataPost.partner = partner;
    dataPost.operation = await hash(JSON.stringify(dataPost));
    dataPost.comment = getUTMCommentForCP();
    setHref(dataPost.operation)
    const result: any = await getStagedPaymentResult(dataPost, token, partner)

    const onSuccess = () => {
      if (getWidgetInstance() === 'ipr2023') yandexEvent('integr-widget_success-payment', 'reachGoal', '90908943');
      googleEvent('widget_payment_success');
      yandexEvent('widget-payment-success');
      setPayedStatus('success');
    };

    if (result?.ioka?.success && result?.ioka?.url) {
      window.location.href = result.ioka.url;
      return;
    }
    if (result.promo && result.success) {
      googleEvent('widget_promo_enter');
      onSuccess();
      return;
    }

    if (result.failed && result.data.length > 0) {
      const error = result.data[0].trim();
      switch (error) {
        case 'PROMO_ALREADY_USED':
          promoError('Промокод уже был использован')
          return;
        case 'PROMO_EXPIRED':
          promoError('Срок действия промокода истек')
          return;
        case 'PROMO_NOT_FOUND':
          promoError('Промокод не найден')
          return;
      }
      throw 'Config error';
    }

    const CustomerReceipt = { ...result.receipt, email };

    const data: IStagedPaymentData = {
      type: 'widget',
      relations,
      finance,
      lablife,
      birth,
      partner,
      cloudPayments: { CustomerReceipt },
      email,
      gender,
      promo,
      subtype: 'idp',
      createdAt: Date.now(),
      autoprediction,
      apDate: dayjs().format('DD.MM.YYYY'),
      apTime: dayjs().format('HH:mm:ss'),
      country,
      isStaged: true,
      K8S_NAMESPACE: result.K8S_NAMESPACE,
      // captchaToken
    };

    data.operation = await hash(JSON.stringify(data));
    data.comment = getUTMCommentForCP();
    setHref(data.operation)
    Object.assign(result.options, {
      InvoiceId: token,
      data,
      email,
      requireEmail: true,
      skin: 'mini',
      type: 'widget',
      country,
    });

    const onCancel = () => {
      yandexEvent('widget-payment-cancel');
      setPayedStatus('failed');
    };

    const cp = async () => {
      try {
        const event = country === 'ru' ? 'widget_payment_start_with_Russ_card' : 'widget_payment_start_with_Foreig_card';
        googleEvent(event);

        await mainApi.payment(result.options, PaymentScheme.CHARGE, {onSuccess, onCancel})
      } catch (error) {
        yandexEvent('widget-payment-fail');
      }
    };
    cp();
    yandexEvent('click-postroit-ipr');
  } catch (error) {
    alert('Сервис временно недоступен!');
    console.log(error);
  }

}
