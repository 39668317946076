import { PaymentMethod } from "../../../helpers/payments";
import { Error, errorDefault } from "./errors";
import { MapData } from './types';


const apiUrl = (process.env.REACT_APP_BACKEND as string) + '/api';

export async function getMap(data: MapData, setError: (value: Error) => void) {
  try {

    const params = Object.entries(data).reduce((acc, next) => `${acc}${next[0]}=${next[1]}&`, '')

    let url = `${apiUrl}/circle?${params}`;

    const result = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'image/png' },
      credentials: 'include'
    });

    const blob = await result.blob()
    return URL.createObjectURL(blob);

  } catch (err) {
    console.log(err);
    setError(errorDefault)
  }
}

export async function getInterpretation(data: MapData, setError: (value: Error) => void) {
  try {

    const params = Object.entries(data).reduce((acc, next) => `${acc}${next[0]}=${next[1]}&`, '')

    let api = data.isFull ? 'get-time-date-info-full' : 'get-time-date-info';

    let url = `${apiUrl}/${api}?${params}`;

    const result = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    }).then((response) => response.json());;

    if (result.failed) {
      setError(errorDefault);
    } else {
      return result;
    }

  } catch (err) {
    console.log(err);
    setError(errorDefault);
  }
}

export async function sendUnisender(data: Pick<MapData, 'name' | 'email'>, setError: (value: Error) => void) {
  try {
    let url = `${apiUrl}/unisender/lablife/add-contact`;

    await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      credentials: 'include'
    })

  } catch (err) {
    console.log(err);
    setError(errorDefault)
  }
}

export function getPrice(paymentMenthod: PaymentMethod) {
  if(paymentMenthod.country === 'ru') {
    return '399 ₽';
  } else if(paymentMenthod.country === 'kz') {
    return '1995 ₸';
  } else {
    return '4 $';
  }
}

export const getDeviceDetect = () => {
  const userAgent = typeof window.navigator === 'undefined'
    ? ''
    : navigator.userAgent;

  const isMobile =
    /android|webos|iphone|ipod|blackberry|bb|playbook|iemobile|windows phone|kindle|silk|opera mini|wpdesktop/i
    .test(userAgent);

  // Workaround to detect ipads safari with turned on "request desctop version" (turned on by default)
  const isIPadDesktop = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

  const isTablet = isIPadDesktop ||
    /Tablet|iPad|Nexus 7|Nexus 10|SM-(T|X)\d+|KFAPWI/i
    .test(userAgent);
  const isIPad = isIPadDesktop || (isTablet && /iPad/i.test(userAgent));

  return { isMobile, isTablet, isIPad };
}