import { CurrencyCode, Region } from "../libs";

export const getIokaLink = (type: 'back' | 'success' | 'fail'): string => {
  let link = window.location.href.replaceAll('https', 'http');
  link = link.replaceAll('http://localhost:8000', process.env.REACT_APP_DAWN!);
  const url: URL = new URL(link);
  url.searchParams.append('ioka-back', type);
  return url.toString();
}

export interface PaymentMethod {
	id: Region
  country: Region
  title: string
	code: CurrencyCode
	symbol: string
}

export const paymentMethods: PaymentMethod[] = [
	{
	  id: Region.ru,
	  country: Region.ru,
	  title: 'Карта РФ',
		code: CurrencyCode.RUB,
		symbol: '₽',
	},
	{
		id: Region.kz,
		country: Region.kz,
	  title: 'Казахстанская карта',
		code: CurrencyCode.KZT,
		symbol: '₸',
	},
	{
	  id: Region.us,
	  country: Region.us,
	  title: 'Иностранная карта',
		code: CurrencyCode.USD,
		symbol: '$'
	},
]

export enum PaymentScheme {
  CHARGE = 'charge',
  AUTH = 'auth',
}
