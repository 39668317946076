import { IPlace, Button, DateTimeInput, Input, InputContainer, PlaceInput } from '../../../libs';
import dayjs from 'dayjs';
import * as EmailValidator from 'email-validator';
import {  useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { mainApi, s3urls } from '../../../helpers/api';
import { usePricesStore } from '../../../store/usePricesStore';
import { LocationIcon, LogoIcon } from '../assets/icons/system';
import { Error } from '../helpers/errors';
import SuccessInfo from './SuccessInfo';
import { getTelegramLink } from '../helpers/api';
import { googleEvent } from '../../../helpers/metrics';

// import ReRecaptcha, { TReReCaptchaApi } from '../../../components/ReRecaptcha';

interface FormProps {
  token: string
  partner: string
  setError(value: Error): void
}

export default function Form(props: FormProps) {
  const defaultDate = dayjs().toISOString()
  const defaultTime = dayjs().utc().set('hour', 12).set('minute', 0).set('second', 0).toISOString();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState<string>(defaultDate);
  const [time, setTime] = useState<string>(defaultTime);
  const [place, setPlace] = useState<IPlace | null>(null);

  const [nameMessage, setNameMessage] = useState<string | undefined>();
  const [emailMessage, setEmailMessage] = useState<string | undefined>();
  const [timeMessage, setTimeMessage] = useState<string | undefined>();
  const [dateMessage, setDateMessage] = useState<string | undefined>();
  const [placeMessage, setPlaceMessage] = useState<string | undefined>();

  const [payedStatus, setPayedStatus] = useState<'success' | 'failed'>();

  // const captchaRef = React.useRef<TReReCaptchaApi | null>(null);

  const price = usePricesStore().prices?.finance_capacity || 0;

  const postfix = <LocationIcon/>

  const isDisabled = useMemo((): boolean => {
    return Boolean(
      nameMessage || !name ||
      emailMessage || !email ||
      timeMessage || !time ||
      dateMessage || !date ||
      placeMessage || !place
    );
  }, [nameMessage, name, emailMessage, email, timeMessage, time, dateMessage, date, placeMessage, place]);

  const onNameChange = (name: string) => {
    const resultName = name.trim();
    setName(name);
    if (/^[a-z,а-я,ё,\s]*$/i.test(resultName)) {
      setNameMessage(resultName.length === 0 ? 'Имя не может быть пустым' : undefined);
    } else {
      setNameMessage('В имени могут быть только буквы');
    }
  }

  const onEmailChange = (email: string) => {
    setEmail(email);
    setEmailMessage(EmailValidator.validate(email) || email === '' ? undefined : 'Введите корректный email');
  }

  const onDateChange = (isoDate?: string) => {
    const message = 'Нужно ввести дату'
    if (isoDate) {
      setDate(isoDate);
      setDateMessage(dayjs(isoDate) < dayjs('1900-01-01') ? 'Введите корректную дату' : dayjs(isoDate) > dayjs() ? 'Введите дату в прошлом' : undefined);
    } else {
      setDateMessage(message);
    }
  }

  const onTimeChange = (isoTime?: string) => {
    const message = 'Нужно ввести время'

    if (isoTime) {
      setTime(isoTime);
      setTimeMessage(undefined);
    } else {
      setTimeMessage(message);
    }
  }

  const onPlaceChange = (place: IPlace | string | null) => {
    if (typeof place === 'object') {
      setPlace(place);
      setPlaceMessage(undefined);
    } else {
      setPlace(place => ({ ...place!, lat: 0, lon: 0 }) );
      setPlaceMessage('Выберите город из списка');
    }
  }

  const checkErrorMode = (val: any) => Boolean(val) ? 'error' : 'normal';

  const handleSubmit = async () => {

    const data = {
      name: name,
      date: dayjs.utc(date).format('DD.MM.YYYY'),
      time: dayjs.utc(time).format('HH:mm:ss'),
      place: place?.name!,
      lat: place?.lat!,
      lon: place?.lon!,
      email
    };

    getTelegramLink(data).then((link: string) => {
      googleEvent('financial_capacity_successful');
      setTimeout(() => {
        window.location.href = link;
      }, 500);
    });
  }


  if(payedStatus === 'success') {
    return <Container>
      <Content payedStatus={payedStatus}>
        <SuccessInfo email={email!} />
      </Content>
    </Container>
  }

  const getLinks = () => {
    return <>
      <Link href={s3urls.termsOfUseRU} target='_blank' rel='noreferrer'>
        Пользовательское соглашение
      </Link>
      &nbsp;и&nbsp;
      <Link href={s3urls.privacyPolicyRU} target='_blank' rel='noreferrer'>
        Политику конфиденциальности
      </Link>
    </>
	}

  return (
    <Container>

      <Header>
        <Logo><LogoIcon /></Logo>
        <Title1>Расчет вашей финансовой емкости</Title1>
        <Title2>Определим ваш возможный максимальный уровень дохода в месяц</Title2>
        <Title3>Расчет делается на основе места, даты и времени вашего рождения</Title3>
      </Header>

      <FormContainer>
        <InputContainer label='Имя' message={nameMessage}>
          <StyledInput
            name="username"
            size='small'
            value={name}
            onChange={onNameChange}
            placeholder='Как Вас зовут?'
            mode={checkErrorMode(nameMessage)}
          />
        </InputContainer>

        <InputContainer label='Почта' message={emailMessage}>
          <StyledInput
            name="email"
            size='small'
            value={email}
            onChange={onEmailChange}
            mode={checkErrorMode(emailMessage)}
            placeholder='Ваш e-mail'
          />
        </InputContainer>

        <InputContainer label='Время рождения' message={timeMessage}>
          <StyledDateTimeInput
            size='small'
            type='time'
            value={time}
            hideIcon={true}
            onChange={onTimeChange}
            onInvalid={onTimeChange}
            mode={checkErrorMode(timeMessage)}
            utcMode
          />
        </InputContainer>

        <InputContainer label='Дата рождения' message={dateMessage}>
          <StyledDateTimeInput
            size='small'
            type='date'
            value={date}
            onChange={onDateChange}
            onInvalid={onDateChange}
            hideIcon={true}
            mode={checkErrorMode(dateMessage)}
            utcMode
          />
        </InputContainer>

        <InputContainer label='Место рождения' message={placeMessage}>
          <StyledPlaceInput
            size='small'
            value={place?.name || ''}
            onSelect={onPlaceChange}
            onChange={onPlaceChange}
            placeholder='Начните вводить место рождения'
            asyncDataFn={mainApi.places.bind(mainApi)}
            asyncDetailFn={mainApi.place.bind(mainApi)}
            mode={checkErrorMode(placeMessage)}
            lang="ru"
            rightIcon={postfix}
          />
        </InputContainer>

        <TelegramInfo>Пришлём информацию в Telegram, а также на почту</TelegramInfo>

        <StyledButton
          name="submit-button"
          color="blue"
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          Рассчитать финансовую емкость
        </StyledButton>

      </FormContainer>

      <Footer>
        <span>
          Нажимая "Рассчитать финансовую емкость", вы даете согласие на рассылку и принимаете <br />
          {getLinks()}
        </span>
      </Footer>

    </Container>
  )
}



const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100vh;
`

const FormContainer = styled.div`
  width: 42.5rem;
  max-width: 85%;
  font-size: 0.875rem;
  margin: 1.5rem 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;

  & > :nth-child(5), & > :nth-child(n + 6) {
    grid-column: 1 / 3;
  }

  @media (max-width: 768px) {
    & > :nth-child(1) {
    grid-column: 1 / 3;
    }

    & > :nth-child(2) {
      grid-column: 1 / 3;
    }
  }
`

const StyledInput = styled(Input) <{ error?: boolean }>`
  ${props => props.error && css`
    input {
      border-color: var(--red-color);
    }
  `};

  @media (max-width: 768px){
    font-size: 1rem;
  }
`

const StyledDateTimeInput = styled(DateTimeInput) <{ error?: boolean }>`
  ${props => props.error && css`
    border-color: var(--red-color);
  `};

  @media (max-width: 768px){
    font-size: 1rem;
  }
`

const StyledPlaceInput = styled(PlaceInput)<{error?: boolean}>`
  /* ${props => props.error && css`
    border: 1px solid var(--red-color);
  `};   */

  div {
    top: 25%;
    left: 95%;
  }

  @media (max-width: 768px){
    font-size: 1rem;

    div {
      top: 25%;
      left: 90%;
    }
  }
`

const StyledButton = styled(Button)`
   color: white !important;
   background: #4760E7 !important;
   font-size: 1.25rem;
   cursor: pointer;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;
  padding-bottom: 3rem;

  @media (max-width: 768px){
    font-size: 0.8rem;
    max-width: 90%;
  }
`;

const Link = styled.a`
   color: var(--blue-color) !important;
`


const Content = styled.div<{sidebar?: string, payedStatus?: string}>`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (max-width: 1280px) {
    height: 100%;
    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  @media (max-width: 768px) {
    width: 100vw !important;
  }
`;


const TelegramInfo = styled.div`
  background: #F2F2F2;
  border: 1px solid #D0D0D033;
  padding: 1rem;
  text-align: left;
  border-radius: 0.75rem;
`

const Header = styled.div`
  max-width: 85%;
`


const Logo = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 3rem;
`

const Title1 = styled.div`
  font-size: 3rem;
  line-height: 3.75rem;
  letter-spacing: -0.022em;
  color: #4760E7;
  margin-bottom: 1.25rem;

`

const Title2 = styled.div`
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: -0.022em;
  color: #000000;
  margin-bottom: 2.5rem;

`

const Title3 = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  color: #7B7B7B;
`